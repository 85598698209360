#product-image {
    width: 40px;
    height: 40px;
    background-size: cover;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
}
.paginationBttns{
    width:100%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
}
.paginationBttns a{
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 2px solid #FE5F55;
  cursor: pointer;
  color: #888;
}
.paginationBttns a:hover{
    color: #fff;
    background-color: #FE5F55;
}
.paginationActive a{
    color: #fff !important;
    background-color: #FE5F55;
}