@media screen and (max-width: 1750px)
{
	.using_onresponsie{-ms-flex: 0 0 33.333%; flex: 0 0 33.333%; max-width: 33.333%;}
	.margin_top_cate03 {margin-top: 44px; }
	
}



@media screen and (max-width: 1550px)
{

}


@media screen and (max-width: 1450px)
{
	.list li {padding-right: 5px;}

}

@media screen and (max-width: 1350px)
{
	.using_onresponsie{-ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%;}	
	.margin_top_cate02 {margin-top: 0px; }
}

@media screen and (max-width: 1320px)
{
	.main_board_inner { overflow-x: hidden;}

	.tabscroll_inner {width: 260px; }
	.tabscroll_perent {min-width: 100%; overflow-x: auto; }

	.tablesecton_mian {display: flex; width: 1130px; padding-right: 10px; }
}



@media screen and (max-width: 1199px)
{


	.main_oflistingForm { padding: 15px 15px 30px 15px;}

	.Table-row{flex-direction: column;}
	.Table-row .Table-row-item:nth-of-type(odd)::after{display: none;}
	.Table-row .Table-row-item:nth-of-type(odd) {max-width: 100%; min-width: 100%; /* border-bottom: 1px solid #d6d6d6; */}
	.Table-row .Table-row-item:nth-of-type(even) {padding-left: 10px;padding-top: 0px; }


	/* .tablesecton_mian {overflow-x: auto; max-width: 260px; min-width: 100%; }
	.tableflex { min-width: 910px;} */


	.custom_fuild_addM {padding-right: 15px;}

}


@media screen and (max-width: 991px)
{
	.stat_body .col-sm-6{ -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; margin-bottom: 15px; padding-right: 0px;}
	.color_bright {width: 40px;}
	.header_head {padding: 14px 30px;}
	.main_board{padding-top: 62px;}
	.logo_header {margin-bottom: 1px;}
	.logo_header a img {max-height: 36px; }
	.logo_header a {display: block; text-align: left; padding: 11px; padding-left: 0px; }


	.main_oflistingForm { flex-direction: column;}
	.left_ofall_tabs{width: 100%;}
	.mycuetTab li {display: inline-block; width: auto; margin-bottom: 2px !important; }
	.listtabbs {margin-bottom:15px; }
	.listtabbs .nav-tabs{padding-bottom: 15px;}

	.requlab_span {margin-left: 6px; font-size: 10px; }

	.using_onresponsie{-ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%;}
	.margin_top_cate01, .margin_top_cate03 {margin-top: 0; }
	.customfuid_mainp {padding-right: 15px; }

}


@media screen and (max-width: 767px)
{
	.product-view .img-wall {
		padding: 30px 0;
	}
	.onlyfordesk{display: block;}
	.menuformob  a{color: #ffffff !important;}
	.menuformob {display: table-cell; vertical-align: middle; padding-left: 15px;width: 30px; }
	.left_menubar { left: -250px; transition: all 0.3s;}
	.main_board {padding-left: 0px;padding-top: 54px;}
	.header_head {padding: 10px 15px; }
	.logomobil {text-align: left;}
	.logomobil a img {max-height: 30px; max-width: 70px; }

	.left_menubar.active {visibility: visible; opacity: 1; left: 0px; z-index: 9999; }
	.overflow-none{overflow: hidden;}

		.transparent-layer{position: fixed;left: 0;top: 0;width: 100%;height: 100%;z-index: 98;background: transparent;display: block;visibility: hidden;opacity: 0;pointer-events: none;}
	.product-listing-sideBar{position: relative;z-index: 100!important;}
	.left_menubar.active .transparent-layer{visibility: visible;opacity: 1;pointer-events: initial;}

		#filter-closer {padding: 5px; text-align: right; position: absolute; right: 10px; cursor: pointer; top: 7px;display: block;}
	#filter-closer img{width: 16px;}

	.main_board_inner { padding: 15px; padding-right: 15px; padding-top: 30px;}
	.systeminfob a.btn_white span{display: none;}
	.oblyformob_log, .welcome_onlymob{display: block;}
	.welconadmin.welcome_onlymob p {color: #fff; margin-bottom: 15px; font-size: 12px; }
	.mabot_mainli {margin-bottom: 8px; }

	.welcomadmin{display: none;}
	.logout{display: none;}
	.userinterfaceb {width: 146px; }
	.color_bleft a { font-size: 10px;}

	.sidebar-wrapper {z-index: 9999;}

	.sidebar-wrapper .sidebar-menu ul li a:nth-of-type(1) { padding: 0px 10px 0px 10px;}
	.sidebar-wrapper .sidebar-menu .sidebar-submenu ul li a { padding: 6px 10px 6px 10px;}
	.chiller-theme .sidebar-wrapper .mabot_mainli a:first-child { font-size: 12px;}
	.sidebar-wrapper {width: 174px; padding-left: 10px; }
	.tabicon_logo{display: none;}
	.requlab_span { margin-left: 0;}

	.editfix { min-width: 100%;}
	.editfix .dropdownsid, .editfix .fuilddesh{width: 100%;}
	.table_fixe {padding: 15px 10px 15px 0px; padding-bottom: 100px;}

	.fixedit_modal .editfix {right: 0; left: 0; }

    body.overflow-none .left_menubar {
        visibility: visible;
        opacity: 1;
        left: 0px;
        z-index: 9999;
    }

    body.overflow-none{
        overflow: hidden;
    }
    body.overflow-none .left_menubar .transparent-layer {
        visibility: visible;
        opacity: 1;
        pointer-events: initial;
    }

}

@media screen and (max-width: 480px)
{
	.stat_body { padding: 10px 10px 20px 10px;}
	.main_board {padding-left: 0px;padding-top: 51px;}
	.fuilddesh { min-width: 100%; width: 100%;}
	.radioequal_flex { flex-direction: column;}
	.dropdownsid {display: block; width: 100%; }
	.Table{/* padding-right: 0px; */ margin-right: 0px;}
	.minwidth02 {min-width: 100% !important; }
	.mycuetTab li a {font-size: 10px; padding: 7px 8px;}

	.repet_single .fuilddesh { width: 80%; }
	.repwat_one .fuilddesh { min-width: 100% !important; }
	.repwat_two .fuilddesh { min-width: 100% !important; }
	.repwat_one, .repwat_two{width: 50%;}

	.addpkgbtn {margin-top: 0px; margin-bottom: 20px; }


}

@media screen and (max-width: 379px)
{
	.userinterfaceb {width: 116px; }
	.userinterfaceb .btn_white, .systeminfob .btn_white {height: 28px; font-size: 11px; padding: 6px 10px; }
	.menuformob {padding-left: 10px; width: 24px; }

}
@media screen and (max-width: 350px)
{
.flexright_addmore a {font-size: 10px; padding: 8px 6px; }
.flexleft_addmore span {font-size: 10px; padding: 8px 8px; }
.customfuid_mainp .subbottom_extra .flexright_addmore {width: 90px;}
.clsubsml_right a { font-size: 12px;}
.clsubsml_right {width: 40px; }
.clsubsml_left { font-size: 10px;}
.listofa_categ li span { font-size: 12px;}

}