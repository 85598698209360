.field_sec_repetM.repeatDisUnset{
    display:unset;
}
.flexCatGrow{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.flexCatGrow .left_innserSearchone{
    margin-left:15px
    }
    
    .repwat_one.multSelectWidth .multiselect-container {
        width: auto!important;
        min-width: 100%  !important;
        background: #fff;
    }
   .repwat_one.multSelectWidth  .search-wrapper {padding:0 }
   .repwat_one.multSelectWidth  .search-wrapper input{ width: 100%; margin:0;}