.ReactModal__Content.ReactModal__Content--after-open {
    /* max-width: 500px; */
    margin: auto;
    height: auto;
    background: #fff;
    padding: 30px;
   inset:unset!important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open{
    inset: 0;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 9999;
    display: flex; overflow-y: auto;
    justify-content: center;
    align-items: center;
}
.button-conf{
    margin: 30px 10px;
}