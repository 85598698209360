@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}



body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  background: #ffffff;
  font-size: 16px;
  line-height: normal;
  color: #444;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}

ul,
ol,
p,
form,
input,
textarea,
select,
pre,
em,
sub,
sup,
canvas,
section,
article,
aside,
img,
a,
li,
iframe,
table,
nav,
header,
footer,
body,
menu,
button {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  vertical-align: baseline;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  outline: none;
  border: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

*,
after,
before {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

body {
  line-height: 1;
}

a {
  color: #2586f1;
  text-decoration: none;
  /*transition: all 0.2s ease-in-out 0s; -webkit-transition: all 0.2s ease-in-out 0s; outline: 0;*/
}

a:hover {
  text-decoration: none;
  color: #00abb1;
}

img {
  max-width: 100%;
}

input,
button,
a,
select {
  outline: none !important;
}

@media (min-width: 1800px) {
  .container {
    max-width: 1638px;
  }
}

.clearfix {
  clear: both;
}

p {
  line-height: 1.6;
}

.btn.btn-primary i {
  font-size: 12px;
}

.btn-bx {
  margin-left: 30px;
}

.btn {
  background: #34c1c6;
  color: #ffffff;
  font-size: 14px;
  padding: 8px 15px;
  border-radius: 4px;
  line-height: normal;
  border: 2px solid transparent;
  outline: none;
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
  font-weight: 500;
}

.btn:hover {
  background: transparent;
  border-color: #34c1c6;
  color: #34c1c6;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  background: transparent;
  border-color: #34c1c6;
  color: #34c1c6;
}

.same-section {
  clear: both;
  display: block;
  width: 100%;
  float: left;
}

.same-section::after {
  content: "";
  display: block;
  clear: both;
}

.same-section::before {
  content: "";
  display: block;
  clear: both;
}

/*@-moz-document url-prefix() { 
  .type_signup ul li label:before {
     top:2px;
  }
}*/

/*jeet*/

.bg_light_gray {
  background: #f7f7ff;
}

.main_bxshadow {
  box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.16);
}

.topsmall_top {
  padding-top: 10px;
}

.topsmall_bottom {
  padding-bottom: 10px;
}

.topsmall_margin_top {
  margin-top: 10px;
}

.topsmall_margin_bottom {
  margin-bottom: 10px;
}

.small_padding_top {
  padding-top: 30px;
}

.small_padding_bottom {
  padding-bottom: 30px;
}

.small_margin_top {
  margin-top: 30px;
}

.small_margin_bottom {
  margin-bottom: 30px;
}

.small_margin_right {
  margin-right: 30px;
}

.small_margin_left {
  margin-left: 30px;
}

.padding_top {
  padding-top: 50px;
}

.padding_bottom {
  padding-bottom: 50px;
}

.margin_top {
  margin-top: 50px;
}

.margin_bottom {
  margin-bottom: 50px;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.onlink {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.main_board {
  display: flex;
  -webkit-display: flex;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  padding-top: 98px;
  padding-left: 200px;
}

.header_inner {
  display: table;
  -webkit-display: flex;
  /* justify-content: flex-end; */
  text-align: right;
  width: 100%;
}

.header_head {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fe5f55;
  padding: 32px 100px;
  padding-left: 230px;
  box-shadow: 0 6px 10px 0 rgba(82, 63, 105, 0.16);
  z-index: 100;
  padding-right: 30px;
}

/* .head_logo a img {height: 50px; } 
.head_logo{width: 180px;}
.headertoggle_ico{font-size: 24px;color: #cccccc;}
.headuser_side{width: 100%; display: flex; -webkit-display: flex;}
.header_menuicon{width: 40px; align-self: center;}
.user_head_profile{width: 100%; display: flex; -webkit-display: flex;flex-direction: row-reverse;align-self: center;}
.userimg_box{width: 40px;height: 40px;border-radius: 50%;overflow: hidden; border: 1px solid #cccccc;}
.userimg_box img{height: 100%;width: 100%;object-fit: cover; -webkit-object-fit: cover;}
.user_headname{text-align: left; align-self: center; padding-left: 10px;position: relative;}
.user_headname span{text-transform: capitalize;color: #6c7293;font-size: 14px;font-family: 'Mont-SemiBold';}
#shows{cursor: pointer;} */
.main_board_inner {
  display: flex;
  -webkit-display: flex;
  width: 100%;
  padding: 30px;
  padding-right: 30px;
}

.work_space {
  display: flex;
  -webkit-display: flex;
  flex: 1 1;
  flex-direction: column;
}

/* .gride_box_perent {display: block; clear: both; width: 100%; }
.user_headname span span {color: rgba(149, 156, 182, 0.63); }
.name_short{display: inline-block;width: 40px;height: 40px; color: #0abb87 !important; background: rgba(10,187,135,.1);border-radius: 4px; font-size: 20px !important;font-family: 'Mont-SemiBold'; text-align: center; margin-left: 10px;}
.name_short div {display: inline-block; line-height: 40px; }
.user_headname:hover {transition: all .3s; background-color: rgba(77,89,149,.06);} */

/*== Checkbox ==*/
.list-check-box input {
  position: absolute;
  width: 0;
  height: 0;
  visibility: hidden;
}

.list-check-box label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  display: inline-block;
  color: #646c9a;
  font-size: 14px;
  text-transform: none;
  text-transform: initial;
  font-weight: 600;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.list-check-box label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  border: 2px solid;
  background: #fff;
  border-color: #ddd;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.list-check-box label:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 3px;
  width: 10px;
  height: 10px;
  background: url() no-repeat;
  /* transform: translateY(-50%); */
  background-size: 100%;
  visibility: hidden;
  opacity: 0;
  /* background-size: 100%; */
}

.list-check-box input[type="checkbox"]:checked+label:before {
  border-color: #2bbabf;
}

.list-check-box input[type="checkbox"]:checked+label:after {
  visibility: visible;
  opacity: 1;
}

.list-check-box label:hover:before {
  border-color: #fe5f55;
}

/*== END Checkbox ==*/

/*=== browse button ===*/
.input-file-trigger {
  display: block;
  padding: 14px 45px;
  background: #0075ff;
  color: #fff;
  font-size: 14px;
  transition: all 0.4s;
  cursor: pointer;
  text-align: center;
}

.input-file {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  padding: 14px 30px;
  cursor: pointer;
}

.input-file:hover+.input-file-trigger,
.input-file:focus+.input-file-trigger,
.input-file-trigger:hover,
.input-file-trigger:focus {
  background: #0f0c44;
  color: #0075ff;
}

/*=== END browse button ===*/

/*=== radio button ===*/
.list__item {
  position: relative;
}

.list li {
  display: inline-block;
  padding-right: 20px;
}

ul.list {
  display: block;
}

.list__item input[type="radio"]:checked,
.list__item input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.list__item input[type="radio"]:checked+label,
.list__item input[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  display: inline-block;
  color: #212121;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.list__item input[type="radio"]:checked+label:before,
.list__item input[type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0px;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 100%;
  background: #fff;
  border-color: #ddd;
}

.list__item input[type="radio"]:checked+label:before {
  border-color: #0075ff;
}

.list__item input[type="radio"]:checked+label:after,
.list__item input[type="radio"]:not(:checked)+label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #0075ff;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  transition: all 0.2s ease;
}

.list__item input[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.list__item input[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*=== END radio button ===*/

.common_padding_shadow {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 12px rgba(0, 0, 0, 0.12);
  padding: 30px;
}

.nav-item {
  display: inline-block;
}

.nav-link {
  display: inline-block;
  padding: 9px 14px;
  font-size: 12px;
  color: #595d6e;
  border-radius: 3px;
}

.nav-item .nav-link.active {
  background-color: rgba(93, 120, 255, 0.1);
  color: #5d78ff;
}

.nav-link:hover {
  color: #595d6e;
}

.second_main_title {
  color: #48465b;
  font-size: 18px;
}

.tabs_sec_perent.common_padding_shadow {
  padding: 0px;
}

.only_comm_padding {
  padding: 30px;
}

.border_top_only {
  border-top: 1px solid #ebedf2;
}

.border_bottom_only {
  border-bottom: 1px solid #ebedf2;
}

.tabbutt.nav {
  place-content: flex-end;
}

.header_columan .col-md-6 {
  align-self: center;
}

.header_columan {
  height: 82px;
}

.logo_header {}

.logo_header a {
  padding: 22px 19px;
  padding-left: 0px;
  display: block;
}

.btn_white {
  display: inline-block;
  background: #ffffff;
  color: #000000;
  font-size: 14px;
  padding: 8px 14px;
  border-radius: 4px;
  border: 2px solid transparent;
  outline: none;
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
  font-weight: 500;
  line-height: 1;
}

.btn_white:hover {
  display: inline-block;
  background: #ffffff;
  color: #000000;
  font-size: 14px;
  padding: 8px 14px;
  border-radius: 4px;
  border: 2px solid transparent;
  outline: none;
  font-weight: 500;
  line-height: 1;
}

.welcomadmin {
  color: #ffffff;
  font-size: 12px;
}

.logout a,
.icon_mail a {
  color: #ffffff;
  font-size: 12px;
}

.logout a:hover {
  color: #495867;
}

.userinterfaceb .navbar {
  display: inline;
  display: initial;
  padding: 0px;
  margin: 0px;
}

.userinterfaceb .dropdown-menu li a {
  display: block;
  color: #000;
  font-size: 14px;
  padding: 6px 10px;
}

.userinterfaceb .dropdown-menu li a:hover {
  background: #495867;
  color: #ffffff;
}

.systeminfob,
.userinterfaceb,
.welcomadmin,
.icon_mail,
.logout {
  display: table-cell;
  vertical-align: middle;
}

.logout {
  width: 65px;
  padding-left: 15px;
}

.icon_mail {
  width: 20px;
}

.welcomadmin {
  width: 154px;
}

.userinterfaceb {
  width: 158px;
  padding-left: 10px;
}

.userinterfaceb .dropdown-toggle::after {
  display: none;
}

.systeminfob img {
  display: inline-block;
  vertical-align: bottom;
}

.userinterfaceb .btn_white {
  height: 34px;
  line-height: 1;
}

.main_title {
  font-size: 22px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 15px;
  line-height: 1;
}

.stat_body {}

.statistics_main {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.16);
}

.stat_body {
  padding: 20px 20px 30px 20px;
  height: 250px;
  overflow-y: auto;
}

.colorbox_list {
  display: flex;
  padding-top: 6px;
}

.color_bleft {
  flex: 1 1;
  border-bottom: 1px dashed #e3e3e4;
}

.color_bright {
  width: 32px;
}

.colorbox {
  width: 12px;
  height: 12px;
  display: inline-block;
}

.color_bleft a {
  font-size: 12px;
  color: #000000;
  text-decoration: underline;
  margin-left: 10px;
}

.color_bright p {
  font-size: 12px;
  color: #000000;
  text-decoration: underline;
}

.stat_head {
  background: #fe5f55;
  color: #ffffff;
}

.listing_title {
  font-size: 14px;
  color: #000000;
  font-weight: bold;
}

.stat_body .col-sm-6:nth-of-type(1) {
  margin-bottom: 15px;
}

.stat_body .col-sm-6 {
  margin-bottom: 15px;
}

.boxlabel_title {
  font-size: 18px;
  padding: 10px 15px;
}

.color_Gray {
  background: #f0f0f0;
}

.color_Gray02 {
  background: #d7d7d7;
}

.color_Green01 {
  background: #a8c067;
}

.color_Green02 {
  background: #d2e798;
}

.color_Skyblue {
  background: #c0ecee;
}

.color_Pink {
  background: #fbc4c4;
}

.breadcrumb_list li {
  display: inline-block;
  font-size: 12px;
  color: #000000;
  font-weight: bold;
}

.breadcrumb_list li a {
  font-size: 12px;
  color: #000000;
  font-weight: 400;
}

.main_oflistingForm {
  display: flex;
  padding: 30px 0px 30px 30px;
}

.left_ofall_tabs {
  width: 170px;
}

.mycuetTab li {
  display: block;
  width: 100%;
}

.mycuetTab li a {
  font-size: 11px;
  color: #000000;
  display: block;
  padding: 12px 15px;
  text-align: left;
  font-weight: 600;
  background: #bdd5ea;
  border-radius: 5px 0px 0px 5px !important;
  border: 3px solid #f7f7ff !important;
  padding-left: 10px;
  border-top-right-radius: 0px !important;
  border-top: 0px !important;
  border-bottom: 3px solid #f7f7ff !important;
}

.mycuetTab li a.active,
.mycuetTab li a:hover {
  background-color: rgb(254 95 85) !important;
  color: #fbfbfb !important;
}

.tabcont_lable {
  font-size: 13px;
  padding: 10px 15px;
  background: #fe5f55;
  color: #ffffff;
}

.right_all_TContent {
  flex: 1 1;
}

.tabcont_lable span {
  text-decoration: underline;
}

.labelsidetop {
  padding-top: 10px;
  padding-left: 15px;
}

.tabcont_Sublable {
  font-size: 13px;
  padding: 10px 15px;
  background: #fe5f55;
  color: #ffffff;
  border-radius: 5px;
}

.Table {
  display: flex;
  flex-direction: column;
  border: 1px solid #f2f2f2;
  font-size: 1rem;
  margin: 0.5rem;
  margin-left: 0px;
  line-height: 1.5;
  justify-content: space-between;
}

.Table-header {
  font-weight: 700;
  background-color: #defdec;
}

.Table-row {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -moz-flex;
  display: flex;
  flex-flow: row nowrap;
}

.Table-row:nth-of-type(even) {
  background-color: #f7f7ff;
}

.Table-row:nth-of-type(odd) {
  background-color: #ffffff;
}

.Table-row-item {
  display: flex;
  flex-grow: 1;
  padding: 0.5em;
  word-break: break-word;
}

.fuilddesh {
  width: 100%;
  background: #f3f3f3;
  border: 1px solid #959595;
  border-radius: 5px;
  min-height: 30px;
  padding: 4px 15px 4px 10px;
  font-size: 13px;
  min-width: 150px !important;
}

.lefttabl_lable span {
  font-size: 13px;
  color: #000000;
  font-weight: 600;
}

.Table-row .Table-row-item:nth-of-type(odd) {
  max-width: 250px;
  min-width: 250px;
  position: relative;
}

.Table-row .Table-row-item:nth-of-type(even) {
  padding-left: 15px;
}

.Table-row .Table-row-item:nth-of-type(odd)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 2px;
  height: 30px;
  background: #d6d6d6;
  transform: translate(0px, -50%);
  -webkit-transform: translate(0px, -50%);
}

.boxrepetform {
  margin-bottom: 15px;
}

.next_fuildequ {
  color: #585858;
  font-size: 13px;
  padding-left: 6px;
  font-weight: 500;
}

.minwidth02 {
  min-width: 280px !important;
}

.dropradio {
  color: #585858;
  font-size: 11px;
  padding-left: 6px;
  font-weight: 500;
  align-self: center;
}

.radioequal_flex {
  display: flex;
}

.savesidebutt {
  height: 34px;
  color: #ffffff;
  background: #577399;
  font-weight: 600;
  font-size: 12px;
  border-radius: 5px;
  border: 0px;
  box-shadow: none;
  outline: none;
  padding: 4px 30px;
  cursor: pointer;
}

.savesidebutt:hover {
  background: #395a86;
  color: #ffffff;
}

.footerside {
  background: #577399;
  padding: 15px 30px;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.footercont {
  color: #ffffff;
  font-size: 14px;
}

.footercont strong {
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
}

.left_menubar .mCSB_inside>.mCSB_container {
  margin-right: 0px;
}

.onlyfordesk {
  display: none;
}

.mobile-stuff {
  display: none;
}

#filter-closer {
  display: none;
}

.oblyformob_log,
.welcome_onlymob {
  display: none;
}

.menutab_design {
  background: #2c3c4c;
  color: #838383;
  display: inline-block;
  padding: 4px 10px;
  border-radius: 3px;
  font-size: 14px;
}

.menutab_design i {
  padding-right: 2px;
  padding-left: 2px;
}

.menutab_design:hover {
  color: #ffffff;
}

.tabicon_logo {
  padding: 0px 15px 20px 0px;
}

.menutab_design {
  box-shadow: 1px 0px 1px #8a8a8a, 0 0px 0px #666,
    inset 4px -3px 1px rgba(0, 0, 0, 0.5),
    inset 0 1px 1px rgb(148 148 148 / 80%);
}

.menutab_design:focus,
.menutab_design:hover {
  box-shadow: 0 1px 2px #636262, 0 -1px 1px #666,
    inset -1px 0px 1px rgba(0, 0, 0, 0.5), inset 0 1px 1px rgb(0 0 0 / 80%);
  background: #404a54;
  border-radius: 4px;
}

.numtwo_click {
  display: none;
}

.hidemenu_title .logoslice02 {
  display: none;
}

.hidemenu_title .sidebar-wrapper {
  width: 70px;
}

.hidemenu_title .main_board {
  padding-left: 70px;
}

.hidemenu_title.hover_menu .sidebar-wrapper {
  width: 200px;
}

.hidemenu_title.hover_menu .main_board {
  padding-left: 200px;
}

.hidemenu_title.hover_menu .mabot_mainli a span {
  display: inline-block !important;
}

.hidemenu_title.hover_menu .sidebar-wrapper .sidebar-menu ul li {
  width: 100% !important;
}

.hidemenu_title.hover_menu .sidebar-wrapper .sidebar-menu ul li a {
  width: 100% !important;
}

.hidemenu_title.hover_menu .logoslice02 {
  display: inline-block;
}

.hidemenu_title .header_head {
  padding-left: 100px;
}

.hidemenu_title.hover_menu .header_head {
  padding-left: 230px;
}

.hidemenu_title .numtwo_click {
  display: inline-block;
}

.hidemenu_title .numone_click {
  display: none;
}

.hidemenu_title .mabot_mainli a span {
  display: none;
}

.hidemenu_title .sidebar-wrapper .sidebar-menu ul li a {
  width: auto;
}

.hidemenu_title .sidebar-wrapper .sidebar-menu ul li a i {
  text-align: center;
}

.hidemenu_title .numtwo_click {
  box-shadow: 0 1px 2px #636262, 0 -1px 1px #666,
    inset -1px 0px 1px rgba(0, 0, 0, 0.5), inset 0 1px 1px rgb(0 0 0 / 80%);
  background: #404a54;
  border-radius: 4px;
}

.hidemenu_title .mabot_mainli {
  text-align: right;
}

.flexradioS {
  display: flex;
}

.flexradioS.special_display {
  display: inline;
  display: initial;
}

.labelsidefx p {
  font-size: 13px;
  color: #000000;
  font-weight: 600;
}

.labelsidefx {
  padding-right: 10px;
  align-self: center;
}

.multial_lbel {
  display: flex;
  flex-wrap: wrap;
}

.equalspan_cont {
  color: #585858;
  font-size: 11px;
  padding-left: 6px;
  font-weight: 500;
  align-self: center;
}

.fuilddesh_textarea {
  width: 100%;
  min-height: 130px;
}

.onlyfor_full_width {
  width: 100%;
}

.radioequal_flex .form_group {
  align-self: center;
}

.onliperacont {
  padding-left: 25px;
}

.onliperacont ul li {
  margin-top: 15px;
  list-style: decimal;
}

.onliperacont ul {
  padding: 15px;
}

#basic014 .onliperacont {
  padding-top: 15px;
}

.onliperacont ul li a {
  color: #fe5f55;
}

.equalspan_cont .list-check-box label {
  font-size: 12px;
}

/*== Listing Plans ==*/
.onlyforcol_support {
  display: block;
}

.addpackk {
  display: flex;
  flex-direction: row-reverse;
}

.addpkgbtn {
  margin-top: -56px;
  line-height: 33px;
  height: -webkit-min-content;
  height: min-content;
  display: inline-block;
  font-size: 14px;
}

.inner_listing_formS {
  display: flex;
  padding-right: 15px;
}

.srch_fuildsecT {
  display: flex;
}

.left_innserSearchone {}

.left_innserSearch {
  flex: 1 1;
}

.leftlabs {
  flex: 1 1;
  align-self: center;
  justify-content: flex-end;
  text-align: right;
  padding-right: 15px;
}

.lefelab_fuild {
  width: 200px;
}

.tablesecton_mian {
  display: flex;
}

.tableflex {
  display: table;
  width: 100%;
}

.tableHead_row {
  display: table-row;
}

.tableHead_row .table_cell {
  display: table-cell;
  background: #fe5f55;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  line-height: 40px;
  height: 40px;
  text-align: left;
  padding-left: 15px;
}

.table_row {
  display: table-row;
}

.table_row .table_cell {
  display: table-cell;
  text-align: left;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
}

.table_bbtnn a {
  font-size: 16px;
  color: #000000;
}

.table_bbtnn {
  padding-right: 6px;
}

.table_bbtnn a:hover {
  color: #fe5f55;
}

.table_bbtnn {
  position: relative;
}

.headeredi_fix {
  background: #577399;
  padding: 15px 30px;
}

.editfix {
  position: fixed;
  top: 0px;
  right: -1100px;
  height: 100vh;
  background: #ffffff;
  z-index: 999;
  max-width: 1100px;
  min-width: 560px;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}

.fixedit_modal .editfix {
  right: 0;
}

.table_fixe {
  padding: 30px 30px;
}

.table_fixe {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 100px;
  max-height: 100vh;
}

.fixhbtn {
  color: #ffffff;
  font-size: 20px;
  margin-left: 10px;
}

.fixhbtn:hover {
  color: #fe5f55;
}

.editfix .Table-row:nth-of-type(odd) {
  background-color: #ffffff;
}

.editfix .Table-row:nth-of-type(even) {
  background-color: #ffffff;
}

.editfix .Table {
  border: 0px;
}

.editfix .Table-row .Table-row-item:nth-of-type(odd) {
  max-width: 190px;
  min-width: 190px;
}

.table_row:nth-of-type(odd) {
  background: #ffffff;
}

.labltable {
  display: inline-block;
  font-weight: 400;
  border-radius: 3px;
  padding: 4px 6px;
  color: #ffffff;
  font-size: 12px;
}

.blacklab {
  background: #495867;
}

.redlab {
  background: #fe5f55;
}

.greenlab {
  background: #52a744;
}

.lablesct {
  background: #5bc0de;
  border-radius: 100px;
  padding-left: 10px;
  padding-right: 10px;
}

.lablesct_red {
  background: #fe5f55;
  border-radius: 100px;
  padding-left: 10px;
  padding-right: 10px;
}

.pagination_custom li {
  display: inline-block;
}

.pagination_custom li a {
  width: 30px;
  height: 30px;
  display: inline-block;
  background: #577399;
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  border-radius: 3px;
  line-height: 29px;
}

.pagination_custom li a:hover {
  background: #395a86;
  color: #ffffff;
}

.showingflexx {
  display: flex;
  align-items: center;
}

.pagination_custom li.active a {
  background: #fe5f55;
  color: #ffffff;
  pointer-events: none;
}

.showingleft {
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  width: 30%;
}

.flexcoluman {
  flex-direction: column;
}

.left_paginistion {
  flex: 1 1;
  text-align: right;
}

.pagination_custom li:first-child a {
  background: transparent;
  color: #000000;
}

.pagination_custom li:last-child a {
  background: transparent;
  color: #000000;
}

.acttiimmg {
  width: 80px;
  height: 80px;
  display: inline-block;
  border-radius: 100px;
  overflow: hidden;
}

.acttiimmg img {
  width: 100%;
  height: 100%;
}

.cateimag {
  width: 100px;
}

.catetabl_image {
  display: flex;
  padding-top: 6px;
}

.catetlist {
  flex: 1 1;
}

.listofca_tbl {
  display: table;
}

.listosa_tbl {
  display: table-row;
}

.leftlist_tbl,
.rightlist_tbl {
  display: table-cell;
  padding-top: 6px;
  font-size: 12px;
  font-weight: 600;
}

.rightlist_tbl {
  padding-left: 6px;
}

.customfuid_mainp {
  padding-right: 30px;
}

.listofa_categ {
  padding-top: 20px;
}

.listofa_categ li {
  display: inline-block;
  /* background: #bdd5ea; padding: 10px 15px; margin-right: 10px; margin-bottom: 10px;border-radius: 5px;padding-right: 70px; */
}

.listofa_categ li span {
  color: #000000;
  font-weight: bold;
  font-size: 16px;
  display: inline-block;
  padding-right: 15px;
}

.listofa_categ li a {
  color: #000000;
  font-weight: bold;
  font-size: 16px;
}

.listofa_categ li a:hover {
  color: #fe5f55;
}

.headclck_flex {
  display: flex;
  background: #bdd5ea;
  padding: 10px 20px;
}

.headclk_cell:nth-of-type(1) {
  flex: 1 1;
  text-align: left;
}

.headclk_cell:nth-of-type(2) {
  text-align: right;
  width: 40px;
}

.titofclicked_head {
  color: #000000;
  font-weight: bold;
  font-size: 18px;
  padding-left: 20px;
}

.headclk_cell:nth-of-type(1) a {
  color: #000000;
  font-weight: bold;
  font-size: 18px;
}

.headclk_cell:nth-of-type(2) a {
  color: #000000;
  font-weight: bold;
  font-size: 18px;
}

.headclk_cell:nth-of-type(2) a:hover {
  color: #fe5f55;
}

.boxrepetform {
  background: #ffffff;
}

.clickhead_side .Table {
  border: 0px solid #f2f2f2;
  margin: 0;
}

.addmore_flex {
  display: flex;
}

.flexleft_addmore span {
  display: block;
  color: #ffffff;
  background: #fe5f55;
  font-weight: 600;
  font-size: 14px;
  padding: 12px 15px;
  border-radius: 5px;
}

.flexright_addmore {
  padding-left: 15px;
}

.flexright_addmore a {
  color: #ffffff;
  display: block;
  background: #fe5f55;
  font-weight: 600;
  font-size: 14px;
  padding: 12px 15px;
  border-radius: 5px;
}

.clickhead_side .Table-row .Table-row-item:nth-of-type(odd) {
  max-width: 90px;
  min-width: 90px;
}

.clickhead_side .Table-row .Table-row-item:nth-of-type(even) {
  max-width: auto;
  min-width: auto;
}

.clickhead_side .dropdownsid {
  width: 100%;
}

.clickhead_side .fuilddesh {
  min-width: 100% !important;
  width: 100%;
}

.flexleft_addmore {
  width: 140px;
}

.flexright_addmore {
  flex: 1 1;
}

.subsml_flrex {
  display: flex;
  background: #bdd5ea;
  padding: 10px 20px;
  border-radius: 5px;
}

.clsubsml_left {
  text-align: left;
  flex: 1 1;
  color: #000000;
  font-weight: bold;
  font-size: 14px;
}

.clsubsml_right {
  width: 60px;
  text-align: right;
}

.clsubsml_right a {
  color: #000000;
  font-weight: bold;
  font-size: 16px;
}

.margin_top_cate01 {
  margin-top: 44px;
}

.margin_top_cate02 {
  margin-top: 88px;
}

.margin_top_cate03 {
  margin-top: 132px;
}

.customfuid_mainp .subbottom_extra .flexleft_addmore {
  flex: 1 1;
}

.customfuid_mainp .subbottom_extra .flexright_addmore {
  min-width: 111px;
  flex: initial;
}

.forsaltitle_cFields {
  color: #ffffff;
  float: left;
  font-weight: bold;
  font-size: 16px;
}

.allcatedit_list li {
  display: inline-block;
  width: auto;
}

.allcatedit_list li a {
  display: inline-block;
  border: 0px;
  border-radius: 0px;
  padding: initial;
  padding: 0px 3px;
}

.postionedt_cate {
  position: absolute;
  top: 0;
  right: 10px;
  line-height: 37px;
}

.allcatedit_list li span {
  cursor: pointer;
  padding: 0px;
  position: relative;
  background: #bdd5ea;
  padding: 10px 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding-right: 70px;
}

.listofa_categ .nav-item .nav-link.active {
  background-color: rgb(254 95 85);
  color: #ffffff;
}

.listofa_categ .nav-item .nav-link.active .postionedt_cate a {
  color: #ffffff;
}

.listofa_categ .nav-item .nav-link.active .postionedt_cate a:hover {
  color: #000000;
}

.fieldsec_lab {
  color: #000000;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 6px;
}

.bindfields h6 {
  color: #000000;
  font-weight: bold;
  font-size: 12px;
}

.fuilddesh {
  margin-top: 0px;
}

.bindfields {
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.fieldsec_lab a {
  color: #000000;
  margin-left: 15px;
}

.field_sec_repetM {
  display: flex;
}

.requlab_span {
  display: inline-block;
  margin-left: 10px;
  /*  background: #ffffff; */
  margin-top: 5px;
  color: #000000;
  padding: 4px 10px;
  font-weight: bold;
  font-size: 14px;
}

.requlab_span i {
  color: #0075ff;
}

.repwat_two {
  padding-left: 15px;
}

.repet_single {
  padding-left: 30px;
}

.repet_single i {
  color: #656565;
  font-size: 16px;
  margin-left: 4px;
}

.addfieldbt_last a {
  display: inline-block;
  background: #fe5f55;
  font-weight: 600;
  font-size: 14px;
  padding: 12px 15px;
  border-radius: 5px;
  color: #ffffff;
}

.htmlallowed {
  font-size: 14px;
  color: #656565;
  font-weight: bold;
}

.addfieldbt_last a {
  margin-right: 10px;
  margin-bottom: 10px;
}

.custom_fuild_addM {
  padding-right: 30px;
}

.tab-pane {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 10px;
}

.lablesctV {
  background: #5cb85c !important;
}

.ratetabl a {
  color: #fe5f55;
  font-size: 14px;
}

.ratetabl span {
  color: #fe5f55;
}

.Tableips {
  display: table;
  width: 100%;
  margin-top: 15px;
}

.tableip_row {
  display: table-row;
}

.tablip_cell {
  display: table-cell;
  width: 50%;
  padding: 0.5em;
}

/* .tableip_row:nth-of-type(odd) .tablip_cell{border-bottom: 2px solid #000000;} */
.tableip_row .tablip_cell {
  border-bottom: 1px solid #000000;
}

.tableip_row .tablip_cell:nth-of-type(odd) {
  border-right: 1px solid #000000;
}

.tablespecial_accounts .tableflex .table_cell p {
  font-size: 12px;
  font-weight: 600;
}

/*--left menu css--*/
@-webkit-keyframes swing {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  10% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }

  30% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  40% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }

  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }

  70% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  80% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes swing {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  10% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }

  30% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  40% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }

  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }

  70% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  80% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@-webkit-keyframes sonar {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes sonar {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

body {
  font-size: 0.9rem;
}

.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand>a,
.sidebar-wrapper .sidebar-dropdown>a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
  transition: all 0.3s ease;
}

/*----------------page-wrapper----------------*/

.page-wrapper {
  height: 100vh;
}

.page-wrapper .theme {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 4px;
  margin: 2px;
}

.page-wrapper .theme.chiller-theme {
  background: #1e2229;
}

/*----------------toggeled sidebar----------------*/

.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
}

@media screen and (min-width: 768px) {
  .page-wrapper.toggled .page-content {
    padding-left: 300px;
  }
}

/*----------------show sidebar button----------------*/
#show-sidebar {
  /*
  position: fixed;
  left: 0;
  top: 10px;
  border-radius: 0 4px 4px 0px;
  width: 35px;*/
  transition-delay: 0.2s;
}

.page-wrapper.toggled #show-sidebar {
  left: -40px;
}

/*----------------sidebar-wrapper----------------*/

.left_menubar {
  background: #495867;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 115;
  /*     max-height: calc(100% - 0px);
    height: calc(100% - 0px);
    padding-top: 20px;
    padding-left: 10px; */
}

.sidebar-wrapper {
  width: 200px;
  position: relative;
  left: 0;
  padding-left: 15px;
  transition: all 0.2s;
  /*     max-height: calc(100% - 0px);
    height: calc(100% - 0px);
    overflow-y: auto; */
}

.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-wrapper a {
  text-decoration: none;
}

/*----------------sidebar-content----------------*/

.sidebar-content {
  /* max-height: calc(100% - 63px); height: calc(100% - 63px); overflow-y: auto; position: relative;  */
}

.sidebar-content.desktop {
  overflow-y: hidden;
}

/*--------------------sidebar-brand----------------------*/

.sidebar-wrapper .sidebar-brand {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.sidebar-wrapper .sidebar-brand>a {
  text-transform: uppercase;
  font-weight: bold;
  flex-grow: 1;
}

.sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 20px;
}

/*--------------------sidebar-header----------------------*/

.sidebar-wrapper .sidebar-header {
  padding: 20px;
  overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic {
  float: left;
  width: 60px;
  padding: 2px;
  border-radius: 12px;
  margin-right: 15px;
  overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.sidebar-wrapper .sidebar-header .user-info {
  float: left;
}

.sidebar-wrapper .sidebar-header .user-info>span {
  display: block;
}

.sidebar-wrapper .sidebar-header .user-info .user-role {
  font-size: 12px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status {
  font-size: 11px;
  margin-top: 4px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status i {
  font-size: 8px;
  margin-right: 4px;
  color: #5cb85c;
}

/*-----------------------sidebar-search------------------------*/

.sidebar-wrapper .sidebar-search>div {
  padding: 10px 20px;
}

/*----------------------sidebar-menu-------------------------*/

.sidebar-wrapper .sidebar-menu {
  padding-bottom: 10px;
}

.sidebar-wrapper .sidebar-menu .header-menu span {
  font-family: "Mont Book";
  font-size: 14px;
  padding: 15px 20px 5px 20px;
  display: inline-block;
}

.sidebar-wrapper .sidebar-menu ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 6px 10px 6px 10px;
  text-align: right;
}

.sidebar-wrapper .sidebar-menu ul li a i {
  font-size: 11px;
  width: 20px;
  height: 20px;
  text-align: right;
  border-radius: 4px;
  line-height: 20px;
}

.sidebar-wrapper .sidebar-menu ul li a:hover>i::before {
  display: inline-block;
  -webkit-animation: swing ease-in-out 0.5s 1 alternate;
          animation: swing ease-in-out 0.5s 1 alternate;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown>a:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  font-style: normal;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 14px;
  display: none;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
  padding: 5px 0;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
  padding-left: 25px;
  font-size: 13px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
  content: "\f111";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  font-size: 8px;
  display: none;
}

.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
  float: right;
  margin-top: 0px;
}

.sidebar-wrapper .sidebar-menu .sidebar-submenu {
  display: none;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a:after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  right: 17px;
}

.page-wrapper .page-content {
  display: inline-block;
  width: 100%;
  padding-left: 0px;
  padding-top: 20px;
}

.page-wrapper .page-content>div {
  padding: 20px 40px;
}

.page-wrapper .page-content {
  overflow-x: hidden;
}

/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #525965;
  border: 0px none #ffffff;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: #525965;
}

::-webkit-scrollbar-thumb:active {
  background: #525965;
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: transparent;
}

::-webkit-scrollbar-track:active {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/*-----------------------------chiller-theme-------------------------------------------------*/

.chiller-theme .sidebar-wrapper {
  background: #495867;
}

.chiller-theme .sidebar-wrapper .sidebar-header,
.chiller-theme .sidebar-wrapper .sidebar-search,
.chiller-theme .sidebar-wrapper .sidebar-menu {
  border-top: 0px solid #3a3f48;
}

.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
  border-color: transparent;
  box-shadow: none;
}

.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
.chiller-theme .sidebar-wrapper .sidebar-brand>a,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
.chiller-theme .sidebar-footer>a {
  color: #495867;
  background: #bdd5ea;
}

.chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu ul li a {
  background: #495867;
  color: #ffffff;
}

.chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu ul li a.active {
  background: #577399;
  color: #ffffff;
}

.chiller-theme .sidebar-wrapper .mabot_mainli a:first-child {
  border-radius: 5px 0px 0px 5px;
  font-size: 12px;
}

.mabot_mainli {
  margin-bottom: 3px;
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover>a,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info,
.chiller-theme .sidebar-wrapper .sidebar-brand>a:hover,
.chiller-theme .sidebar-footer>a:hover i {
  color: #ffffff;
  background: #fe5f55;
}

.page-wrapper.chiller-theme.toggled #close-sidebar {
  color: #bdbdbd;
}

.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
  color: #ffffff;
}

.chiller-theme .sidebar-wrapper ul li:hover a i,
.chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu:focus+span,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
  color: #ffffff;
  text-shadow: 0px 0px 10px rgba(2, 173, 179, 0.5);
}

/*.chiller-theme .sidebar-wrapper .sidebar-menu ul li a i,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    background: #272361;
}*/

.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
  color: #6c7b88;
}

.chiller-theme .sidebar-footer {
  background: #3a3f48;
  box-shadow: 0px -1px 5px #282c33;
  border-top: 1px solid #464a52;
}

.chiller-theme .sidebar-footer>a:first-child {
  border-left: none;
}

.chiller-theme .sidebar-footer>a:last-child {
  border-right: none;
}

/*--END left menu css--*/

li.sidebar-dropdown ul li a {
  background: transparent !important;
  color: #fff !important;
}

.table_row .table_cell {
  white-space: nowrap;
  vertical-align: top;
}

.lefelab_fuild input {
  /* border: 1px solid #fe5f55; */
  /* border-radius: 0; */
  font-size: 14px;
  box-shadow: none;
}

/* pagination */
.pagination {
  margin-top: 25px;
  justify-content: center;
}

.pagination .page-item {
  margin-right: 3px;
}

.pagination .page-item .page-link {
  border-radius: 0;
  background: #577399;
  box-shadow: none;
  outline: 0;
  color: #fff;
  border-radius: 3px;
  transition: all 0.3s linear;
}

.pagination .page-item .page-link.active,
.pagination .page-item .page-link:hover {
  background: #fe5f55;
}

.customLabel {
  width: 15px;
  height: 17px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  position: relative;
  transition: all 0.3s linear;
  display: inline-block;
}

.customLabel::before {
  position: absolute;
  content: "\2713";
  color: #fff;
  font-size: 0;
  transition: all 0.3s ease-in-out;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

input[type="checkbox"]:checked+label.customLabel {
  background: #fe5f55;
  border: 1px solid #fe5f55;
}

input[type="checkbox"]:checked+label.customLabel::before {
  font-size: 20px;
}

.show_more>ul>li button {
  cursor: pointer;
}

table.contactViewTable {
  max-width: 600px;
  margin: auto;
}

table.contactViewTable tbody th {
  width: 130px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.information-sec table.contactViewTable tbody th {
  width: 171px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  padding: 12px 20px;
}

table.contactViewTable tbody td {
  font-size: 15px;
  line-height: 20px;
  padding: 10px 20px;
}

.custom-cards svg {
  max-width: 97px;
}

.custom-cards .text-block h5 {
  font-size: 15px;
  font-weight: 400;
}

.custom-cards .text-block p {
  font-size: 23px;
  font-weight: 600;
}

.custom-cards .card-header {
  text-align: center;
  background-color: rgb(0 0 0 / 9%);
}

.custom-cards .card-header a {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
}

.fuilddesh1 {
  width: 100%;
  background: #fff;
  border: 1px solid #959595;
  border-radius: 5px;
  min-height: 30px;
  padding: 4px 15px 4px 10px;
  font-size: 13px;
}

.edit-form select {
  background: #fff;
  padding: 10px;
}

.edit-form input {
  background: #fff;
  padding: 10px;
}

@media (max-width: 767.98px) {
  .fuilddesh {
    margin-top: 0px;
    min-width: 100% !important;
  }

  .repwat_one,
  .repwat_two {
    width: 100% !important;
  }
}

.bindfields .addfieldbt_last a {
  padding: 7px 15px;
}

.inner-field {
  position: relative;
  margin-bottom: 15px;
}

.inner-field .requlab_span {
  position: absolute;
  right: 30px;
  -webkit-transform: translate(-0, -0);
          transform: translate(-0, -0);
  font-size: 8px;
  top: -5px;
}

.inner-field .requlab_span .check-box i {
  color: #333;
}

.information-sec .card-header {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;
  padding: 12px 10px;
  line-height: 1;
  background: #fe5f55;
}

.information-sec .info-detail .form-group:nth-child(even) {
  background-color: #fff;
}

.info-detail.myprofile-detail {
  font-size: 14px;
}

.information-sec .table td,
.table th {
  text-align: left;
}

.symbol-label {
  background-color: #eee5ff;
  color: #8950fc;
}

.information-sec .card {
  max-width: 550px;
  border: 0;
  margin: 0 auto;
}

.information-sec .card .card-body {
  border: 0;
}

.information-sec .card .card-body h5 {
  font-weight: 600;
  font-size: 17px;
  padding: 0px;
  margin: 0;
}

.information-sec .card .text {
  font-size: 14px;
}

.product-view .card-header {
  background: #fe5f55;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}

.product-view .text-block {
  line-height: 30px;
}

.product-view .text-block p {
  max-width: 700px;
  text-align: justify;
  padding: 0 0 9px 0;
  font-size: 15.5px;
}

.product-view .text-block h4 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  padding: 10px 0;
}

.product-view .img-wall {
  padding: 0px 0;
}

.product-view .card-body {
  padding: 20px 21px 40px;
}

.addInputWidth {
  width: 100%;
}

/* 12may */
.paginationBttns a {
  padding: 5px 12px !important;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #577399 !important;
  cursor: pointer;
  color: #fff !important;
  line-height: -4px;
  background: #577399;
}

.paginationActive a {
  color: #fff !important;
  background-color: #FE5F55;
  border: #fe5f55 solid 2px !important;
}

.paginationBttns .next a:hover {
  color: #fff;
  background-color: transparent !important;
}

.paginationBttns .next a {
  border: 0 !important;
  height: 50px !important;
  background: none;
  padding: 15px !important;
  top: 7px !important;
  position: relative;
  z-index: 1;
  background: transparent;
}

.paginationBttns .next {
  font-size: 0;
  position: relative;
}

.paginationBttns .next:after {
  content: '\f105';
  font-family: 'FontAwesome';
  position: absolute;
  display: block;
  right: 19px;
  font-weight: 600;
  top: -4px;
  font-size: 20px;
}


.paginationBttns .previous a:hover {
  color: #fff;
  background-color: transparent !important;
}

.paginationBttns .previous a {
  border: 0 !important;
  height: 50px !important;
  background: none;
  padding: 15px !important;
  top: 7px !important;
  position: relative;
  z-index: 1;
  background: transparent;
}

.paginationBttns .previous {
  font-size: 0;
  position: relative;
}

.paginationBttns .previous:after {
  content: '\f104';
  font-family: 'FontAwesome';
  position: absolute;
  display: block;
  right: 13px;
  top: -4px;
  font-weight: 600;
  font-size: 20px;
}

.paginationBttns {
  justify-content: flex-end !important;
}

.paginationBttns {
  margin-top: 30px;
}

.paginationBttns a {
  padding: 10px;
  margin: 3px !important;
}


.user-form-hd .table_fixe {
  width: 657px;
}

.user-form-hd .dropdownsid {
  width: 80%;
}

.user-form-hd .main_bxshadow {
  margin: 0 auto;
}

.user-form-hd .Table-row:nth-of-type(even) {
  background-color: #fff;
}

/* .user-form-hd .Table-row{border-bottom: #33333326 solid 1px} */
.user-form-hd .Table {
  border: 0;
}

.ck.ck-editor {
  max-width: 576px;
  width: 100%;
}

.ck-content.ck-editor__editable {
  max-height: 200px;
  font-size: 14px;
  min-height: 200px;
}

.table_fixe .dropdownsid {
  width: 100%;
}

.table_fixe .dropdownsid .fuilddesh {
  width: 100%;
  max-width: 250px;
}


.overForm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .2);
  z-index: 120;
  display: none;
}

.fixedit_modal .overForm {
  display: block;
}

.showINTableRow.Table-row-item {
  flex-wrap: wrap;
}

.multiSelectBars .search-wrapper {
  background: #f3f3f3;
  border: 1px solid #959595;
  border-radius: 5px;
  min-height: 30px;
  padding: 3px 15px 3px 10px;
  font-size: 13px;
  min-width: 150px !important;
  max-width: 250px;
}

.multiSelectBars input {
  color: #444;
}

.multiSelectBars .optionListContainer {
  max-width: 250px;
}

.table-row-flex {
  flex-wrap: wrap;
}

.modalChild {
  text-align: center;
}

.modalChild button {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 16px;
  padding: 0 15px;
}

.close-savesidebutt {
  background: #FE5F55;
}

.ReactModal__Content.ReactModal__Content--after-open {
  box-shadow: 0 0 7px 0 rgb(0 0 0 / 20%);
  border-radius: 10px !important;
}

.modalchildPadding {
  color: #000;
  font-weight: 600;
  text-align: center;
  font-size: 28px;
  padding: 0 15px;
  margin-top: 30px;
}

.modalchildPadding h6 {
  font-size: 20px;
}

.view-product-img .product-view .img-wall {
  padding: 0px 0;
}

.product-viewtbl table td,
.table th {
  border: 1px solid #dee2e6;
}

.product-viewtbl table {
  max-width: 900px;
  width: 100%;
}

.product-viewtbl table th {
  width: 50px;
}


.dropdownsid {
  max-width: 320px;
  width: 100%;
}



.catetabl_image {
  width: 380px;
}

.cursor-pointer {
  cursor: pointer
}



.customer-review-profile_block {
  border: rgb(51 51 51 / 5%) solid 1px;
  overflow: hidden;
  border-radius: 18px;
  padding: 20px 15px;
  padding-bottom: 0 !important;

}

.review_mess .review_reply {
  padding-left: 20px;
}

.review_mess {
  background: #f7f7ff;
  margin-right: -15px;
  margin-left: -15px;
}

.customer-review-profile_block .live-dot {
  position: absolute;
  text-align: left;
  width: 13px;
  height: 13px;
}

.reply_img_b {
  width: 70px;
}

.customer-review-profile_block .review_reply .detail-owner-img img {
  width: 70px;
  height: 70px;
}

.customer-review-profile_block .detail-owner-name-bx {
  padding: 10px 0px 10px 0px;
}

.review_block {
  border: rgb(51 51 51 / 5%) solid 1px;
  border-radius: 18px;
  /* margin-bottom: 30px; */
  padding: 10px 20px;
  margin-bottom: 15px;
}

.form-control-line {
  width: 100%;
  background: transparent;
  border-bottom: 2px solid #6d7985;
  padding: 0 8px 0 8px;
  font-size: 15px;
  color: #333333;
  font-weight: 600;
  height: 35px;
}

.customer-review-profile_block .review_mess .mess-sender-name h2 {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 5px;
}

.flex-g {
  flex-grow: 1;
  text-align: right;
}

.customer-review-profile_block .rated-star {
  text-align: left;
  display: inline-block;
}

.flex-g .form-group {
  display: inline-block;
}

.rated-customer-bx-inner h2 {
  font-size: 14px;
  color: #495867;
  font-weight: 500;
  margin-bottom: 5px;
  line-height: 23px;
  margin-top: 10px;
}

.review_reply .form-control-line {
  padding: 0;
  font-size: 13px;
}

.flex-g i.fa.fa-star {
  font-size: 18px;
  margin-left: 5px;
}

.customer-review-profile_block .review_reply .detail-owner-img img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.review_reply {
  padding-left: 100px;
  padding-right: 20px;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}


.detail-owner-name {
  display: inline-block;
  float: left;
  width: calc(100% - 100px);
  padding-left: 25px;
}

.detail-owner-name-bx .detail-owner-img img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}

.detail-owner-name-bx .detail-owner-img {
  width: 70px;
  position: relative;
  display: inline-block;
  float: left;
}

.detail-owner-name-bx {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.h-30px {
  height: 70px !important;
}

.review_author h3 {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 10px;
}

.customer-review-profile_block .rated-star i {
  font-size: 16px;
  margin-left: 5px;
}

.detail-owner-name-bx .star-ratings {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 5px;
}

.rely_textnotapp {
  position: absolute;
  bottom: 40px;
  right: 0;
}



.review-modal h5 {
  font-size: 17px;
  font-weight: 600;
}
   
.notification-item-list {
  max-height: 230px  ;  overflow: auto;
}

.notification-list .noti-icon {
  font-size: 20px;
  vertical-align: middle;
  color: #2a3142
}

.notification-list .noti-icon-badge {
  display: inline-block;
  position: absolute;
  top: -2px;
  right: -2px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  padding: 0;
  background-color: #33adf5;
  font-size: 15px;
}

.notification-list.ntIcon .dropdown-toggle i {
  font-size: 16px;
  color: #fff;
}
.soldproducttable{  padding: 0 !important;}
.soldproducttable .soldproduct_block {    padding: 28px;
  position: relative;}
  .soldproducttable .soldproduct_block button{     background: #fe5f55;
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: -10px;
    position: absolute; display: flex; align-items: center; justify-content: center;
    right: -10px;
    font-size: 18px;}

    .data_soldproducttable [class*="col"] {
      margin-bottom: 10px;
      font-size: 14px;
      color: #000; line-height: 26px;
      font-weight: 600;
  }
.tablespecial_accounts .tableflex .table_cell  .subtab_editlabl.select_bx {
  min-width: 120px;
}
.data_soldproducttable .img-block img{ max-width: 150px; }



.BoughtSoldProductList_table .labltable.lablesct{ margin-bottom: 5px;}
.notification-list .notify-item {
  padding: 10px 20px
}
.notification-list  .dropdown-toggle::after{
  border-top: 0.3em solid #fff;}

.notification-list .notify-item .notify-icon {
  float: left;
  height: 36px;
  width: 36px;
  text-align: center;
  margin-right: 10px;
  border-radius: 50%;
  color: #2a3142
}

.notification-list .notify-item .notify-icon i {
  height: 32px;
  width: 32px;
  background-color: #fff;
  border-radius: 50%;
  line-height: 30px;
  margin-top: 2px
}

.notification-list .notify-item .notify-details {
  margin-bottom: 0;
  overflow: hidden;
  margin-left: 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500
}
.notification-list .notify-item.dropdown-item.active, .notification-list .notify-item.dropdown-item:active {
  background-color: #f8f9fa;
  color: #2a3142;
}
.notification-list .notify-item .notify-details span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 12px;
  font-weight: 400
}

.notification-list .profile-dropdown .notify-item {
  padding: 4px 20px
}
.notification-list .dropdown-item-text {
  padding: 15px 10px;
  display: block;
  font-size: 16px;
  color: #495867;
  font-weight: 700;
}
.notification-list .nav-link {
  padding: 0 7px;
}
.notification-list h6
{    font-size: 14px;}


.notification-list.ntIcon{    display: table-cell;
  vertical-align: middle;
  width: 94px;
}
.notification-list.ntIcon .dropdown-menu.show {
  display: block;
  width: 340px;
}

.addfix{
  width: 100%;
}

.fuild-range {
  width: 45%;
  background: #f3f3f3;
  border: 1px solid #959595;
  border-radius: 5px;
  min-height: 30px;
  padding: 4px 15px 4px 10px;
  font-size: 13px;
  margin-right: 8px;
}

.pos-relative {
  display: flex;
}

.add-remove-button {
  font-size: 16px;
  border: 1px solid;
  padding: 2px 6px;
  margin-left: 2px;
  border-radius: 4px;
}

.display_webkit{
  display: -webkit-box;
  margin-bottom: 6px;
}


@media screen and (max-width: 767px) {
  .review_reply {
    padding-left: 40px;


  }
  .ReactModal__Content.ReactModal__Content--after-open{ width: 90% !important;}
  .review_mess .align-items-center {
    align-items: unset !important;
  }

  .customer-review-profile_block .rated-star {
    text-align: left;
    display: inline-block;
    margin: 0 !important;
  }
}



@media screen and (max-width: 575) {
  .review_mess .align-items-center {
    align-items: unset !important;
  }


}

@media (min-width: 576px) {
  .modal-dialog.review-modal {
    max-width: 750px;
    margin: 1.75rem auto;
  }
}





@media screen and (max-width: 1750px)
{
	.using_onresponsie{ flex: 0 0 33.333%; max-width: 33.333%;}
	.margin_top_cate03 {margin-top: 44px; }
	
}



@media screen and (max-width: 1550px)
{

}


@media screen and (max-width: 1450px)
{
	.list li {padding-right: 5px;}

}

@media screen and (max-width: 1350px)
{
	.using_onresponsie{ flex: 0 0 50%; max-width: 50%;}	
	.margin_top_cate02 {margin-top: 0px; }
}

@media screen and (max-width: 1320px)
{
	.main_board_inner { overflow-x: hidden;}

	.tabscroll_inner {width: 260px; }
	.tabscroll_perent {min-width: 100%; overflow-x: auto; }

	.tablesecton_mian {display: flex; width: 1130px; padding-right: 10px; }
}



@media screen and (max-width: 1199px)
{


	.main_oflistingForm { padding: 15px 15px 30px 15px;}

	.Table-row{flex-direction: column;}
	.Table-row .Table-row-item:nth-of-type(odd)::after{display: none;}
	.Table-row .Table-row-item:nth-of-type(odd) {max-width: 100%; min-width: 100%; /* border-bottom: 1px solid #d6d6d6; */}
	.Table-row .Table-row-item:nth-of-type(even) {padding-left: 10px;padding-top: 0px; }


	/* .tablesecton_mian {overflow-x: auto; max-width: 260px; min-width: 100%; }
	.tableflex { min-width: 910px;} */


	.custom_fuild_addM {padding-right: 15px;}

}


@media screen and (max-width: 991px)
{
	.stat_body .col-sm-6{ flex: 0 0 100%; max-width: 100%; margin-bottom: 15px; padding-right: 0px;}
	.color_bright {width: 40px;}
	.header_head {padding: 14px 30px;}
	.main_board{padding-top: 62px;}
	.logo_header {margin-bottom: 1px;}
	.logo_header a img {max-height: 36px; }
	.logo_header a {display: block; text-align: left; padding: 11px; padding-left: 0px; }


	.main_oflistingForm { flex-direction: column;}
	.left_ofall_tabs{width: 100%;}
	.mycuetTab li {display: inline-block; width: auto; margin-bottom: 2px !important; }
	.listtabbs {margin-bottom:15px; }
	.listtabbs .nav-tabs{padding-bottom: 15px;}

	.requlab_span {margin-left: 6px; font-size: 10px; }

	.using_onresponsie{ flex: 0 0 100%; max-width: 100%;}
	.margin_top_cate01, .margin_top_cate03 {margin-top: 0; }
	.customfuid_mainp {padding-right: 15px; }

}


@media screen and (max-width: 767px)
{
	.product-view .img-wall {
		padding: 30px 0;
	}
	.onlyfordesk{display: block;}
	.menuformob  a{color: #ffffff !important;}
	.menuformob {display: table-cell; vertical-align: middle; padding-left: 15px;width: 30px; }
	.left_menubar { left: -250px; transition: all 0.3s;}
	.main_board {padding-left: 0px;padding-top: 54px;}
	.header_head {padding: 10px 15px; }
	.logomobil {text-align: left;}
	.logomobil a img {max-height: 30px; max-width: 70px; }

	.left_menubar.active {visibility: visible; opacity: 1; left: 0px; z-index: 9999; }
	.overflow-none{overflow: hidden;}

		.transparent-layer{position: fixed;left: 0;top: 0;width: 100%;height: 100%;z-index: 98;background: transparent;display: block;visibility: hidden;opacity: 0;pointer-events: none;}
	.product-listing-sideBar{position: relative;z-index: 100!important;}
	.left_menubar.active .transparent-layer{visibility: visible;opacity: 1;pointer-events: initial;}

		#filter-closer {padding: 5px; text-align: right; position: absolute; right: 10px; cursor: pointer; top: 7px;display: block;}
	#filter-closer img{width: 16px;}

	.main_board_inner { padding: 15px; padding-right: 15px; padding-top: 30px;}
	.systeminfob a.btn_white span{display: none;}
	.oblyformob_log, .welcome_onlymob{display: block;}
	.welconadmin.welcome_onlymob p {color: #fff; margin-bottom: 15px; font-size: 12px; }
	.mabot_mainli {margin-bottom: 8px; }

	.welcomadmin{display: none;}
	.logout{display: none;}
	.userinterfaceb {width: 146px; }
	.color_bleft a { font-size: 10px;}

	.sidebar-wrapper {z-index: 9999;}

	.sidebar-wrapper .sidebar-menu ul li a:nth-of-type(1) { padding: 0px 10px 0px 10px;}
	.sidebar-wrapper .sidebar-menu .sidebar-submenu ul li a { padding: 6px 10px 6px 10px;}
	.chiller-theme .sidebar-wrapper .mabot_mainli a:first-child { font-size: 12px;}
	.sidebar-wrapper {width: 174px; padding-left: 10px; }
	.tabicon_logo{display: none;}
	.requlab_span { margin-left: 0;}

	.editfix { min-width: 100%;}
	.editfix .dropdownsid, .editfix .fuilddesh{width: 100%;}
	.table_fixe {padding: 15px 10px 15px 0px; padding-bottom: 100px;}

	.fixedit_modal .editfix {right: 0; left: 0; }

    body.overflow-none .left_menubar {
        visibility: visible;
        opacity: 1;
        left: 0px;
        z-index: 9999;
    }

    body.overflow-none{
        overflow: hidden;
    }
    body.overflow-none .left_menubar .transparent-layer {
        visibility: visible;
        opacity: 1;
        pointer-events: initial;
    }

}

@media screen and (max-width: 480px)
{
	.stat_body { padding: 10px 10px 20px 10px;}
	.main_board {padding-left: 0px;padding-top: 51px;}
	.fuilddesh { min-width: 100%; width: 100%;}
	.radioequal_flex { flex-direction: column;}
	.dropdownsid {display: block; width: 100%; }
	.Table{/* padding-right: 0px; */ margin-right: 0px;}
	.minwidth02 {min-width: 100% !important; }
	.mycuetTab li a {font-size: 10px; padding: 7px 8px;}

	.repet_single .fuilddesh { width: 80%; }
	.repwat_one .fuilddesh { min-width: 100% !important; }
	.repwat_two .fuilddesh { min-width: 100% !important; }
	.repwat_one, .repwat_two{width: 50%;}

	.addpkgbtn {margin-top: 0px; margin-bottom: 20px; }


}

@media screen and (max-width: 379px)
{
	.userinterfaceb {width: 116px; }
	.userinterfaceb .btn_white, .systeminfob .btn_white {height: 28px; font-size: 11px; padding: 6px 10px; }
	.menuformob {padding-left: 10px; width: 24px; }

}
@media screen and (max-width: 350px)
{
.flexright_addmore a {font-size: 10px; padding: 8px 6px; }
.flexleft_addmore span {font-size: 10px; padding: 8px 8px; }
.customfuid_mainp .subbottom_extra .flexright_addmore {width: 90px;}
.clsubsml_right a { font-size: 12px;}
.clsubsml_right {width: 40px; }
.clsubsml_left { font-size: 10px;}
.listofa_categ li span { font-size: 12px;}

}
.repeat-section-login-New {
    padding: 30px 0px;
    margin: auto;
    min-height: calc(100vh - 241px);
}

.loginbx_main_New {
    max-width: 450px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.log_sign_inner_New {
    width: 100%;
    padding: 30px 45px;
}

.New-W-50 {
    width: 100%!important;
}

.formgrop_main_New .form-group {
    margin-bottom: 10px;
}

.show_more ul {
    height: 105px;
    overflow: hidden;
}

.show_more ul.active {
    height: auto;
    transition: 1s;
}

.show_more ul li {
    margin-bottom: 5px;
    list-style: none !important;
    position: relative;
}

.show_more .toggle_btn_New {
    margin-top: 15px;
    font-weight: 700;
    color: #495867;
    cursor: pointer;
    font-size: 15px;
    text-align: center;
}

.show_more .toggle_btn_New.active .fas {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.logintab_New {
    border: none;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 50px;
    padding: 5px;
}

.logintab_New .nav-link {
    background: #cccccc;
    border: none;
    border-radius: 100px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    color: #495867;
}

.logintab_New .nav-link:hover {
    background: linear-gradient(180deg, rgba(254, 140, 132, 1) 0%, rgba(254, 98, 88, 1) 100%);
    border: none;
    border-radius: 100px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
}

.logintab_New .nav-link.active {
    background: linear-gradient(180deg, rgba(254, 140, 132, 1) 0%, rgba(254, 98, 88, 1) 100%);
    border: none;
    border-radius: 100px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
}

.btn-social-Nsign {
    color: #495867;
    padding: 12px 30px;
    border-radius: 12px;
    border: none;
    cursor: pointer;
    border: 1px solid #495867;
    width: 100%;
    font-size: 13px;
    font-weight: 600;
    display: inline-block;
}

.btn-social-Nsign:hover {
    background-color: #495867;
    color: #fff;
}

.f-12 {
    font-size: 12px;
}

.log_sign_inner {
    width: 100%;
    padding: 30px 45px;
}

.text_New {
    font-size: 12px;
    color: #B3B3B3;
}

.eyeshow_New {
    position: absolute;
    top: 5px;
    right: 10px;
}

.custom_cheak_New ul li {
    display: inline-block;
    vertical-align: middle;
}

.margin_top_new {
    margin-top: 25px;
}

.headingsign_New p {
    text-align: center;
    font-size: 12px;
    color: #495867;
    max-width: 250px;
    margin: auto;
}

.headingsign_New {
    text-align: center;
    margin-bottom: 30px;
}

.marginfour_side {
    margin: 10px;
}

.btn-secondaryx {
    color: #ffffff;
    padding: 12px 30px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    /* ff3.6+ */
    /* safari4+,chrome */
    /* safari5.1+,chrome10+ */
    /* opera 11.10+ */
    /* ie10+ */
    background: linear-gradient(180deg, rgba(254, 140, 132, 1) 0%, rgba(254, 98, 88, 1) 100%);
    /* w3c */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#fe8c84', endColorstr='#fe6258', GradientType=0);
    /* ie6-9 */
}

.btn-secondaryx:hover {
    opacity: 0.8;
}

.New_control {
    font-size: 15px;
    color: #333333;
}

.donthave_New p a {
    color: rgba(254, 98, 88, 1);
    font-size: 12px;
}

.donthave_New {
    padding-top: 0px;
}

.signpass_New {
    position: relative;
}

.formgrop_main_New .btn-secondaryx {
    border-radius: 100px;
    text-transform: uppercase;
    letter-spacing: 1;
}

@media screen and (max-width: 567px) {
    .log_sign_inner_New {
        padding: 30px 15px;
    }
}

/* ------------------- new csss */

.scndHeader{
    background: #fff;
    box-shadow: 0 0 27px -11px rgba(0, 0, 0, .33);
    padding: 15px;
    margin-bottom: 50px;
}
.loginheadName{
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    color: #fe6a60;
}
.loginHeader{
    text-align:center;
}

.tab-pane{
    border-bottom:0;
}


.ReactModal__Content.ReactModal__Content--after-open {
    /* max-width: 500px; */
    margin: auto;
    height: auto;
    background: #fff;
    padding: 30px;
   inset:unset!important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open{
    inset: 0;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button-conf{
    margin: 30px 10px;
}
.savesidebutt{
    margin-right: 8px;
}
.ReactModal__Content.ReactModal__Content--after-open {
    /* max-width: 500px; */
    margin: auto;
    height: auto;
    background: #fff;
    padding: 30px;
   inset:unset!important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open{
    inset: 0;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 9999;
    display: flex; overflow-y: auto;
    justify-content: center;
    align-items: center;
}
.button-conf{
    margin: 30px 10px;
}
#add-category:hover{
    background-color: white!important;
    color:#FE5F55;
    border: 2px solid #FE5F55;
}
#add-category:hover{
    background-color: white!important;
    color:#FE5F55;
    border: 2px solid #FE5F55;
}
.field_sec_repetM.repeatDisUnset{
    display:unset;
}
.flexCatGrow{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.flexCatGrow .left_innserSearchone{
    margin-left:15px
    }
    
    
#add-category:hover{
    background-color: white!important;
    color:#FE5F55;
    border: 2px solid #FE5F55;
}
.field_sec_repetM.repeatDisUnset{
    display:unset;
}
.flexCatGrow{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.flexCatGrow .left_innserSearchone{
    margin-left:15px
    }
    
    .repwat_one.multSelectWidth .multiselect-container {
        width: auto!important;
        min-width: 100%  !important;
        background: #fff;
    }
   .repwat_one.multSelectWidth  .search-wrapper {padding:0 }
   .repwat_one.multSelectWidth  .search-wrapper input{ width: 100%; margin:0;}
#add-category:hover{
    background-color: white!important;
    color:#FE5F55;
    border: 2px solid #FE5F55;
}
.repwat_one.multSelectWidth .multiselect-container{
    width:auto!important;
    min-width:200px;
}
.repwat_one.multSelectWidth{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.field_sec_repetM.repeatDisUnset{
    display:unset;
}
.flexCatGrow{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.flexCatGrow .left_innserSearchone{
    margin-left:15px
    }
    
    
.repwat_one.multSelectWidth .multiselect-container{
    width:auto!important;
    min-width:200px;
}
.repwat_one.multSelectWidth{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
#product-image {
    width: 40px;
    height: 40px;
    background-size: cover;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
}
.paginationBttns{
    width:100%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
}
.paginationBttns a{
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 2px solid #FE5F55;
  cursor: pointer;
  color: #888;
}
.paginationBttns a:hover{
    color: #fff;
    background-color: #FE5F55;
}
.paginationActive a{
    color: #fff !important;
    background-color: #FE5F55;
}


.loader-wrapper {
  background: rgba(255, 255, 255, 0.84);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
}



.loader {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}

.loader:before {
  content: "";
  width:100px;
  height: 100px;
  border: 5px solid #fe605567;
  border-top-color: #fe5f55;
  border-radius: 50%;
  -webkit-animation: spin 1.5s infinite linear;
          animation: spin 1.5s infinite linear;
  display: block;
  
}


@keyframes spin{

  0%{
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100%{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }

}
@-webkit-keyframes spin{

  0%{
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100%{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }

}

.loader img {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loader-wrapper {
    background: rgba(255, 255, 255, 0.84);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 9999;
    top: 0;
    left: 0;
  }
  
  .loader {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
  }
  
  .loader:before {
    content: "";
    width: 100px;
    height: 100px;
    border: 5px solid #fe605567;
    border-top-color: #fe5f55;
    border-radius: 50%;
    -webkit-animation: spin 1.5s infinite linear;
            animation: spin 1.5s infinite linear;
    display: block;
  }
  
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  .loader img {
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .addDiscountOption {
    height: 34px;
    color: #ffffff;
    background: #577399;
    font-weight: 600;
    font-size: 12px;
    border-radius: 5px;
    border: 0px;
    box-shadow: none;
    outline: none;
    padding: 4px 30px;
}

.discount_row {
  display: block !important;
}

.discount-option-required{
  font-weight:normal;
  font-size:15px;
}

.repwat_one.multSelectWidth .multiselect-container{
    width: auto!important;
    min-width: 400px;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
.loader-wrapper {
  background: rgba(255, 255, 255, 0.84);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
}

.loader {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}

.loader:before {
  content: "";
  width: 100px;
  height: 100px;
  border: 5px solid #fe605567;
  border-top-color: #fe5f55;
  border-radius: 50%;
  -webkit-animation: spin 1.5s infinite linear;
          animation: spin 1.5s infinite linear;
  display: block;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loader img {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader-wrapper {
  background: rgba(255, 255, 255, 0.84);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
}

.loader {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}

.loader:before {
  content: "";
  width: 100px;
  height: 100px;
  border: 5px solid #fe605567;
  border-top-color: #fe5f55;
  border-radius: 50%;
  -webkit-animation: spin 1.5s infinite linear;
          animation: spin 1.5s infinite linear;
  display: block;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loader img {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader-wrapper {
  background: rgba(255, 255, 255, 0.84);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
}

.loader {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}

.loader:before {
  content: "";
  width: 100px;
  height: 100px;
  border: 5px solid #fe605567;
  border-top-color: #fe5f55;
  border-radius: 50%;
  -webkit-animation: spin 1.5s infinite linear;
          animation: spin 1.5s infinite linear;
  display: block;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loader img {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loader-wrapper {
    background: rgba(255, 255, 255, 0.84);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 9999;
    top: 0;
    left: 0;
  }
  
  .loader {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
  }
  
  .loader:before {
    content: "";
    width: 100px;
    height: 100px;
    border: 5px solid #fe605567;
    border-top-color: #fe5f55;
    border-radius: 50%;
    -webkit-animation: spin 1.5s infinite linear;
            animation: spin 1.5s infinite linear;
    display: block;
  }
  
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  .loader img {
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
.loader-wrapper {
    background: rgba(255, 255, 255, 0.84);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 9999;
    top: 0;
    left: 0;
  }
  
  .loader {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
  }
  
  .loader:before {
    content: "";
    width: 100px;
    height: 100px;
    border: 5px solid #fe605567;
    border-top-color: #fe5f55;
    border-radius: 50%;
    -webkit-animation: spin 1.5s infinite linear;
            animation: spin 1.5s infinite linear;
    display: block;
  }
  
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  .loader img {
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
