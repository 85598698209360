.repeat-section-login-New {
    padding: 30px 0px;
    margin: auto;
    min-height: calc(100vh - 241px);
}

.loginbx_main_New {
    max-width: 450px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.log_sign_inner_New {
    width: 100%;
    padding: 30px 45px;
}

.New-W-50 {
    width: 100%!important;
}

.formgrop_main_New .form-group {
    margin-bottom: 10px;
}

.show_more ul {
    height: 105px;
    overflow: hidden;
}

.show_more ul.active {
    height: auto;
    transition: 1s;
}

.show_more ul li {
    margin-bottom: 5px;
    list-style: none !important;
    position: relative;
}

.show_more .toggle_btn_New {
    margin-top: 15px;
    font-weight: 700;
    color: #495867;
    cursor: pointer;
    font-size: 15px;
    text-align: center;
}

.show_more .toggle_btn_New.active .fas {
    transform: rotate(180deg);
}

.logintab_New {
    border: none;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 50px;
    padding: 5px;
}

.logintab_New .nav-link {
    background: #cccccc;
    border: none;
    border-radius: 100px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    color: #495867;
}

.logintab_New .nav-link:hover {
    background: linear-gradient(180deg, rgba(254, 140, 132, 1) 0%, rgba(254, 98, 88, 1) 100%);
    border: none;
    border-radius: 100px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
}

.logintab_New .nav-link.active {
    background: linear-gradient(180deg, rgba(254, 140, 132, 1) 0%, rgba(254, 98, 88, 1) 100%);
    border: none;
    border-radius: 100px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
}

.btn-social-Nsign {
    color: #495867;
    padding: 12px 30px;
    border-radius: 12px;
    border: none;
    cursor: pointer;
    border: 1px solid #495867;
    width: 100%;
    font-size: 13px;
    font-weight: 600;
    display: inline-block;
}

.btn-social-Nsign:hover {
    background-color: #495867;
    color: #fff;
}

.f-12 {
    font-size: 12px;
}

.log_sign_inner {
    width: 100%;
    padding: 30px 45px;
}

.text_New {
    font-size: 12px;
    color: #B3B3B3;
}

.eyeshow_New {
    position: absolute;
    top: 5px;
    right: 10px;
}

.custom_cheak_New ul li {
    display: inline-block;
    vertical-align: middle;
}

.margin_top_new {
    margin-top: 25px;
}

.headingsign_New p {
    text-align: center;
    font-size: 12px;
    color: #495867;
    max-width: 250px;
    margin: auto;
}

.headingsign_New {
    text-align: center;
    margin-bottom: 30px;
}

.marginfour_side {
    margin: 10px;
}

.btn-secondaryx {
    color: #ffffff;
    padding: 12px 30px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    background: -moz-linear-gradient(270deg, rgba(254, 140, 132, 1) 0%, rgba(254, 98, 88, 1) 100%);
    /* ff3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(254, 140, 132, 1)), color-stop(100%, rgba(254, 98, 88, 1)));
    /* safari4+,chrome */
    background: -webkit-linear-gradient(270deg, rgba(254, 140, 132, 1) 0%, rgba(254, 98, 88, 1) 100%);
    /* safari5.1+,chrome10+ */
    background: -o-linear-gradient(270deg, rgba(254, 140, 132, 1) 0%, rgba(254, 98, 88, 1) 100%);
    /* opera 11.10+ */
    background: -ms-linear-gradient(270deg, rgba(254, 140, 132, 1) 0%, rgba(254, 98, 88, 1) 100%);
    /* ie10+ */
    background: linear-gradient(180deg, rgba(254, 140, 132, 1) 0%, rgba(254, 98, 88, 1) 100%);
    /* w3c */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#fe8c84', endColorstr='#fe6258', GradientType=0);
    /* ie6-9 */
}

.btn-secondaryx:hover {
    opacity: 0.8;
}

.New_control {
    font-size: 15px;
    color: #333333;
}

.donthave_New p a {
    color: rgba(254, 98, 88, 1);
    font-size: 12px;
}

.donthave_New {
    padding-top: 0px;
}

.signpass_New {
    position: relative;
}

.formgrop_main_New .btn-secondaryx {
    border-radius: 100px;
    text-transform: uppercase;
    letter-spacing: 1;
}

@media screen and (max-width: 567px) {
    .log_sign_inner_New {
        padding: 30px 15px;
    }
}

/* ------------------- new csss */

.scndHeader{
    background: #fff;
    box-shadow: 0 0 27px -11px rgba(0, 0, 0, .33);
    padding: 15px;
    margin-bottom: 50px;
}
.loginheadName{
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    color: #fe6a60;
}
.loginHeader{
    text-align:center;
}

.tab-pane{
    border-bottom:0;
}

