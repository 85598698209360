.loader-wrapper {
    background: rgba(255, 255, 255, 0.84);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 9999;
    top: 0;
    left: 0;
  }
  
  .loader {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
  }
  
  .loader:before {
    content: "";
    width: 100px;
    height: 100px;
    border: 5px solid #fe605567;
    border-top-color: #fe5f55;
    border-radius: 50%;
    animation: spin 1.5s infinite linear;
    display: block;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loader img {
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .addDiscountOption {
    height: 34px;
    color: #ffffff;
    background: #577399;
    font-weight: 600;
    font-size: 12px;
    border-radius: 5px;
    border: 0px;
    box-shadow: none;
    outline: none;
    padding: 4px 30px;
}

.discount_row {
  display: block !important;
}

.discount-option-required{
  font-weight:normal;
  font-size:15px;
}
