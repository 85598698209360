.field_sec_repetM.repeatDisUnset{
    display:unset;
}
.flexCatGrow{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.flexCatGrow .left_innserSearchone{
    margin-left:15px
    }
    
    